import type {ComponentType} from 'react'
import React from 'react'
import {Loader} from '@ambler/andive'
import type {Doctor, MedicalTransporterType} from '@ambler/shared'
import hoistNonReactStatics from 'hoist-non-react-statics'
import {useAuth0} from '@auth0/auth0-react'
import {Auth0Client} from '@auth0/auth0-spa-js'
import {useRouter} from 'next/router'
import styled from 'styled-components'
import {useQuery} from '@apollo/client'
import gql from 'graphql-tag'
import {flowRight} from 'lodash'
import {Andiv, DocumentBodyPortal, Text} from '@ambler/andive-next'
import {Browser} from '@capacitor/browser'
import LogoAppBar from '../logo-app-bar'
import {safeLocalStorage} from '../../lib/local-storage'
import {Title} from '../title'
import {useSessionStorage} from '../../hooks/use-storage'
import Sentry from '../../lib/sentry'
import capacitorSecureStorage from '../../lib/mobile/secure-storage'

import {useMutation} from '../../hooks/use-mutation'
import {getUpdateDevicePayload} from '../../lib/firebase-messaging'
import {AppContainer} from './responsive'
import {Auth0Client_cypress, useAuth_cypress} from './cypress'
import type {
  APP_logoutDeviceMutationVariables,
  APP_logoutDeviceMutation_,
  APP_StackAuthMfuName_,
  APP_StackAuthMtName_,
} from './auth.generated'

const A4H_MULTIMFU_CURRENT_ID = 'a4h.dashboard.lastUsedMfuId'
const A4H_AS_FORCE_MFU_ID = 'a4h.dashboard.forceMfuId'
const A4T_AS_FORCE_MT_ID = 'a4t.dashboard.forceMtId'

type SignInOptions = {
  callbackUrl?: string
  signUp?: boolean
  hint?: string
}

type MedicalTransporter = {
  id: string
  name?: string
  type?: MedicalTransporterType
}

type MedicalFacilityUnit = {
  id: string
  name?: string
}

export type MtAcl = {
  canRead: boolean
  canWrite: boolean
  mt: MedicalTransporter
}

export type MfuAcl = {
  canList: boolean
  canOrder: boolean
  canSignFor: Pick<Doctor, 'id'>[]
  mfu: MedicalFacilityUnit
}

export const auth0Params = {
  domain: process.env.AUTH0_ENDPOINT,
  client_id: process.env.AUTH0_CLIENT_ID,
  redirect_uri: (() => {
    if (process.env.IS_MOBILE_BUILD) {
      return `${process.env.BUNDLE_ID}://${process.env.AUTH0_ENDPOINT}/capacitor/${process.env.BUNDLE_ID}/callback`
    }
    return process.env.AMBLER_APP_URL
  })(),
  scope: 'openid email profile offline_access',
  cacheLocation: process.env.IS_MOBILE_BUILD ? undefined : ('localstorage' as any),
  cache: process.env.IS_MOBILE_BUILD ? capacitorSecureStorage : undefined,
  useRefreshTokens: true,
}

export const MultiMfuContext = React.createContext({
  currentMfuId: null,
  setCurrentMfuId: null,
})
const AuthContext = React.createContext<{
  mfuAcls: MfuAcl[]
}>({
  mfuAcls: [],
})

const mtNameQuery = gql`
  query APP_StackAuthMtName($mtId: ID!) {
    medicalTransporter(id: $mtId) {
      id
      name
    }
  }
`

const mfuNameQuery = gql`
  query APP_StackAuthMfuName($mfuId: ID!) {
    medicalFacilityUnit(id: $mfuId) {
      id
      name
    }
  }
`

const logoutDeviceMutation = gql`
  mutation APP_logoutDeviceMutation($data: PushNotificationsUpsertDeviceDataInput!) {
    pushNotificationsUpsertDevice(data: $data)
  }
`

export const isStaffEmail = (email: string) => Boolean(email?.endsWith('@ambler.fr') || email?.endsWith('@amblea.fr'))

const useForcedMtId = (email: string) => {
  const isStaff = isStaffEmail(email)
  const [isLoading, setIsLoading] = React.useState(true)
  const {query} = useRouter()
  const [forcedMtId, setForcedMtId] = useSessionStorage<string>(A4T_AS_FORCE_MT_ID)
  const [, , removeForcedMfuId] = useSessionStorage<string>(A4H_AS_FORCE_MFU_ID)

  React.useEffect(() => {
    if (!isStaff) {
      return
    }
    if (query.forceMt && query.forceMt !== forcedMtId) {
      removeForcedMfuId()
      setForcedMtId(query.forceMt as string)
    }
    setIsLoading(false)
  }, [query.forceMt, forcedMtId, setForcedMtId, removeForcedMfuId, isStaff])

  return isStaff ? ([forcedMtId, isLoading] as const) : ([null, false] as const)
}

const useForcedMfuId = (email: string) => {
  const isStaff = isStaffEmail(email)
  const [isLoading, setIsLoading] = React.useState(true)
  const {query} = useRouter()
  const [forcedMfuId, setForcedMfuId] = useSessionStorage<string>(A4H_AS_FORCE_MFU_ID)
  const [, , removeForcedMtId] = useSessionStorage<string>(A4T_AS_FORCE_MT_ID)

  React.useEffect(() => {
    if (!isStaff) {
      return
    }
    if (query.forceMfu && query.forceMfu !== forcedMfuId) {
      removeForcedMtId()
      setForcedMfuId(query.forceMfu as string)
    }
    setIsLoading(false)
  }, [query.forceMfu, forcedMfuId, setForcedMfuId, removeForcedMtId, isStaff])

  return isStaff ? ([forcedMfuId, isLoading] as const) : ([null, false] as const)
}

// ? e2e tests authentication
const isCypress = typeof window !== 'undefined' && window.Cypress
const useAuth0_ = isCypress ? useAuth_cypress : useAuth0
export const Auth0Client_ = isCypress ? Auth0Client_cypress : Auth0Client
// ? end e2e tests authentication

export const useAuth = (): {
  user: {
    id: string
    auth0Id: string
    name: string
    email: string
    role: string
  }
  mfuAcls: MfuAcl[]
  mtAcls: MtAcl[]
  isAuthenticated: boolean
  isEmailConfirmed: boolean
  isLoading: boolean
  isConnectAs: boolean
  isStaff: boolean
  signIn: (arg0?: SignInOptions) => void
  signOut: () => void
} => {
  const [logoutDevice] = useMutation<APP_logoutDeviceMutation_, APP_logoutDeviceMutationVariables>({
    mutation: logoutDeviceMutation,
  })

  const {user, isAuthenticated, isLoading, loginWithRedirect, logout, buildAuthorizeUrl, buildLogoutUrl} = useAuth0_()

  const [forcedMtId, forcedMtIdLoading] = useForcedMtId(user?.email)
  const [forcedMfuId, forcedMfuIdLoading] = useForcedMfuId(user?.email)

  const isForceLoading = forcedMtIdLoading || forcedMfuIdLoading

  const userProfile = user?.['https://api.ambler.fr/']
  const isConnectAs = !isForceLoading && (Boolean(forcedMtId) || Boolean(forcedMfuId))

  if (!isLoading && isAuthenticated && !userProfile) {
    // ! gut feeling: some users are unable to access despite having isEmailVerified=true and valid ACLs
    // we suspect some client side network configuration to prevent auth0 from sending back the user profile.
    Sentry.captureException(new Error(`Except userProfile to be defined because isAuthenticated is true`))
  }

  const {mfuAcls} = React.useContext(AuthContext)
  const {mtAcls, role, id, name, email, auth0Id, isEmailConfirmed} = userProfile || {}

  return {
    user: {
      id,
      auth0Id,
      name,
      email,
      role,
    },
    mfuAcls,
    mtAcls,
    isAuthenticated,
    isEmailConfirmed,
    isLoading: isLoading || isForceLoading,
    isConnectAs,
    isStaff: isConnectAs || isStaffEmail(email),
    signIn: async (options: SignInOptions = {}) => {
      // ! mobile
      if (process.env.IS_MOBILE_BUILD) {
        const url = (await buildAuthorizeUrl()) as string
        return Browser.open({url})
      } else {
        // ! webapp
        const {signUp, hint, callbackUrl} = options
        const redirectUri = `${auth0Params.redirect_uri}/authcallback`
        const params = {
          redirectUri: callbackUrl ?? redirectUri,
          login_hint: hint,
          screen_hint: signUp && 'signup', // https://auth0.com/docs/authenticate/login/auth0-universal-login/new-experience#signup
        }
        return loginWithRedirect(params)
      }
    },
    signOut: async () => {
      try {
        await logoutDevice({
          variables: {
            data: {
              ...(await getUpdateDevicePayload()),
              state: 'LOGGED_OUT',
              fcmToken: null,
            },
          },
        })
      } catch (_err) {
        // no op but we want the logout to happen
      }
      // ! mobile
      if (process.env.IS_MOBILE_BUILD) {
        const url = buildLogoutUrl({returnTo: auth0Params.redirect_uri}) as string
        await Browser.open({url})
        return logout({localOnly: true})
      } else {
        // ! webapp
        return logout({
          returnTo: `${auth0Params.redirect_uri}/log-in`,
        })
      }
    },
  }
}

export const useMainMt = (): MtAcl => {
  const {isLoading, isAuthenticated, user, isEmailConfirmed, mtAcls} = useAuth()
  const [forcedMtId, forcedMtIdLoading] = useForcedMtId(user?.email)
  const [forcedMfuId, forcedMfuIdLoading] = useForcedMfuId(user?.email)

  const isForceLoading = forcedMtIdLoading || forcedMfuIdLoading

  if (isLoading || isForceLoading || !isAuthenticated || forcedMfuId || !isEmailConfirmed) {
    return null
  }

  if (forcedMtId) {
    return {
      canRead: true,
      canWrite: true,
      mt: {id: forcedMtId},
    }
  }
  return mtAcls?.[0]
}

export const useMainMfu = (): MfuAcl => {
  const {isLoading, isAuthenticated, user, mfuAcls} = useAuth()
  const [forcedMtId, forcedMtIdLoading] = useForcedMtId(user?.email)
  const [forcedMfuId, forcedMfuIdLoading] = useForcedMfuId(user?.email)
  const {currentMfuId} = React.useContext(MultiMfuContext)

  const isForceLoading = forcedMtIdLoading || forcedMfuIdLoading

  // ? https://app.shortcut.com/ambler/story/12716/hello-le-mfu-https-bo-ambler-fr-medical-facility-units-ckyl9emarr1zb0a28lby8h7pu-h%C3%B4pital-loz%C3%A8re
  // ? we should prevent mainMfu to be returned (as we do for mainMt) when email is not verified. But there seem to be a bug with specific network configuration for one mf
  if (isLoading || isForceLoading || !isAuthenticated || forcedMtId) {
    return null
  }

  if (forcedMfuId) {
    return {
      canList: true,
      canOrder: true,
      canSignFor: [],
      mfu: {id: forcedMfuId},
    }
  }

  return mfuAcls.find(acl => acl.mfu.id === currentMfuId) ?? mfuAcls[0]
}

type checkerType = 'MT' | 'MFU' | 'ANY'

const getAllMfuAclsQuery = gql`
  query APP_GetAllMfuAclsQuery {
    mfuAcls: getUserMfuAcls {
      canList
      canOrder
      canSignFor {
        id
        hasSignature
      }
      mfu {
        id
        name
      }
    }
  }
`

const LoadingPage = () => {
  return (
    <>
      <LogoAppBar />
      <AppContainer>
        <Loader />
      </AppContainer>
    </>
  )
}

const GlowingBorder = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  // ? ios notch handling
  margin-top: env(safe-area-inset-top);

  border: 8px solid rgba(229, 181, 135, 0.8);
  z-index: 99999;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;
`
const ConnectAsOverlay = ({asName}: {asName: string}) => (
  <GlowingBorder>
    <Andiv p="8px" borderRadius="4px 0 0 0" bg="rgba(229, 181, 135, 0.8)">
      <Text t="body2">Connecté en tant que :</Text>&nbsp;
      <Text t="body1">{asName}</Text>
    </Andiv>
  </GlowingBorder>
)

const withAuthContext = (Component: ComponentType) => {
  function WithAuthContext() {
    // * withAuthProtection is used around all connected pages, hence this useQuery will be called
    // * on every navigation. To avoid refetching all mfu acls each time, we use a cache-first policy.
    const {isAuthenticated, isLoading, isConnectAs, isEmailConfirmed} = useAuth()
    const {query} = useRouter()
    const [forcedMfuId] = useSessionStorage<string>(A4H_AS_FORCE_MFU_ID)
    const [forcedMtId] = useSessionStorage<string>(A4T_AS_FORCE_MT_ID)
    // ? at first loading, the force id is in the url query param, then in the session storage (see useForcedMtId / useForcedMfuId)
    const forcedMfuId_ = forcedMfuId ?? query?.forceMfu
    const forcedMtId_ = forcedMtId ?? query?.forceMt

    const skipQueries = !isAuthenticated || !isEmailConfirmed

    const mtNamequeryResult = useQuery<APP_StackAuthMtName_>(mtNameQuery, {
      variables: {mtId: forcedMtId_},
      skip: !forcedMtId_ || skipQueries,
      fetchPolicy: 'cache-first',
    })
    const mfuNamequeryResult = useQuery<APP_StackAuthMfuName_>(mfuNameQuery, {
      variables: {mfuId: forcedMfuId_},
      skip: !forcedMfuId_ || skipQueries,
      fetchPolicy: 'cache-first',
    })

    const allMfuAclsqueryResult = useQuery(getAllMfuAclsQuery, {
      fetchPolicy: 'cache-first',
      skip: skipQueries,
    })
    const authContextValue = React.useMemo(
      () => ({mfuAcls: allMfuAclsqueryResult.data?.mfuAcls ?? []}),
      [allMfuAclsqueryResult.data?.mfuAcls],
    )

    if (allMfuAclsqueryResult.loading || mtNamequeryResult.loading || mfuNamequeryResult.loading || isLoading) {
      return <LoadingPage />
    }

    return (
      <AuthContext.Provider value={authContextValue}>
        <Component />
        {isConnectAs && (
          <DocumentBodyPortal>
            <ConnectAsOverlay
              asName={
                mtNamequeryResult?.data?.medicalTransporter?.name ??
                mfuNamequeryResult?.data?.medicalFacilityUnit?.name ??
                '...'
              }
            />
          </DocumentBodyPortal>
        )}
      </AuthContext.Provider>
    )
  }
  hoistNonReactStatics(WithAuthContext, Component)
  return WithAuthContext
}

const withAuthProtection = (type: checkerType) => (Component: ComponentType) => {
  const WithAuthProtection = () => {
    const router = useRouter()
    const [loading, setLoading] = React.useState(true)
    const {isAuthenticated, isLoading, signIn} = useAuth()

    const mainMt = useMainMt()
    const mainMfu = useMainMfu()

    const isErrorPage = router.pathname === '/auth/error'
    const error = router.query['error']
    const errorCode = router.query['error_description']

    const [currentMfuId, setCurrentMfuId] = React.useState(() => safeLocalStorage.getItem(A4H_MULTIMFU_CURRENT_ID))

    React.useEffect(() => {
      safeLocalStorage.setItem(A4H_MULTIMFU_CURRENT_ID, currentMfuId)
    }, [currentMfuId])

    React.useEffect(() => {
      if (!router.isReady) {
        return
      }
      if (error && !isErrorPage) {
        router.replace(`/auth/error?error=${errorCode}`)
        return
      }
      if (isLoading) {
        return
      }
      if (!isAuthenticated) {
        if (process.env.IS_MOBILE_BUILD) {
          router.replace('/')
        } else {
          // ? callbackUrl: we redirect to the previously visited page after Auth0 login
          signIn({callbackUrl: `${auth0Params.redirect_uri}/authcallback?redirect=${router.asPath}`})
        }
      }
      setLoading(false)
    }, [error, errorCode, isErrorPage, isAuthenticated, isLoading, router, signIn])

    if (loading) {
      return <LoadingPage />
    }

    const hasAccess =
      (type === 'ANY' && isAuthenticated) ||
      (type === 'MT' && Boolean(mainMt) && mainMt?.mt.type !== 'DISPATCHER') ||
      (type === 'MFU' && Boolean(mainMfu))

    if (!hasAccess) {
      return (
        <>
          <LogoAppBar />
          <AppContainer>
            <Title>Accès refusé</Title>
            <Andiv p="8px">
              <Text>
                Vous n'avez pas accès à cette page. Si vous pensez qu'il s'agit d'une erreur, n'hésitez pas à nous
                contacter : <a href="mailto:contact@ambler.fr">contact@amblea.fr</a>
              </Text>
            </Andiv>
          </AppContainer>
        </>
      )
    }

    return (
      <MultiMfuContext.Provider
        value={{
          currentMfuId,
          setCurrentMfuId,
        }}
      >
        <Component />
      </MultiMfuContext.Provider>
    )
  }
  hoistNonReactStatics(WithAuthProtection, Component)
  return WithAuthProtection
}

const withAuthStack = (type: checkerType) => flowRight(withAuthContext, withAuthProtection(type))

export function withGenericAuthProtection(Component: ComponentType) {
  const EnhancedComponent = withAuthStack('ANY')(Component)
  hoistNonReactStatics(EnhancedComponent, Component)
  return EnhancedComponent
}

export function withMfuAuthProtection(Component: ComponentType) {
  const EnhancedComponent = withAuthStack('MFU')(Component)
  hoistNonReactStatics(EnhancedComponent, Component)
  return EnhancedComponent
}

export function withMtAuthProtection(Component: ComponentType) {
  const EnhancedComponent = withAuthStack('MT')(Component)
  hoistNonReactStatics(EnhancedComponent, Component)
  return EnhancedComponent
}

export const Accessible: React.FC<{mt?: boolean; mfu?: boolean}> = ({children, mt, mfu}) => {
  const mainMt = useMainMt()
  const mainMfu = useMainMfu()
  if (mt && (!mainMt || !mainMt.canWrite)) {
    return null
  }
  if (mfu && (!mainMfu || !mainMfu.canOrder)) {
    return null
  }
  return <>{children}</>
}
